.content__progress {
  width: 400px;
  position: relative;
  background-color: rgba(white, 0.2);
  height: 2px;

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: red;

    animation: loader 2.2s cubic-bezier(0.8, 0, 0.2, 1);
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}

@keyframes loader {
  0% {
    width: 0%;
    left: 0;
  }

  49% {
    width: 100%;
    left: 0;
  }

  50% {
    width: 100%;
    right: 0;
    left: auto;
  }

  100% {
    width: 0%;
    right: 0;
    left: auto;
  }
}
