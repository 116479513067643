@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.tipping__body {
  padding: 0 12px 0 0;
  border-top: 1px solid #ed1c24;
  border-bottom: 1px solid #ed1c24;
  background-color: $tipping;
  font-family: $tertiaryFont;
  font-size: 16px;
  line-height: 16px;
  margin-left: -7px;
  margin-right: -7px;
  min-height: 100px;
}

.tipping__title {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 56%;
  height: 25px;
  color: $white;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $racingPostRed;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    transform: skew(-45deg);
  }
}

.tipping__title-text {
  position: relative;
  top: 25%;
  padding-left: 13px;
  font-family: $proximaBold;
  font-size: 17px;
}

.sweetspots__text {
  padding: 5px 0 12px 12px;
}

.sweetspots__tip-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 20px;
  p {
    margin-left: 10px;
    font-weight: bold;
  }
}

.sweetspots__img {
  width: 50px;
  height: 50px;
}

.sweetspots__horse-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;

  p {
    padding: 5px 0px;
    font-family: $tertiaryFont;
    font-weight: bold;
  }

  .sweetspots__race-info {
    font-size: 15px;
    padding: 0;
  }
}

.sweetspots__verdict {
  font-family: $tertiaryFont;
  font-size: 17px;
}

.tip__info {
  margin: 12px;
}

.tip__img {
  align-self: center;
  width: 60px;
  margin: -5px 10px 0 0;
  float: left;
  transform: scaleX(-1);
}

.tip__horse-name {
  font-family: $tertiaryFont;
  font-size: 20px;
  font-weight: bold;
}

.tip__verdict {
  margin-top: 5px;
  margin-left: 70px;
}
