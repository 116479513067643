@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';
@import './src/styles/vars.scss';

.elements__container {
  border-top: 1px solid $borderColor;
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px;
}

.elements-top-border-greyout {
  border-top: 1px solid darkgrey;
}

.elements-top-border {
  border-top: 1px solid $borderColor;
}

.element {
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px;
}

.element-side-borders {
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
}

.element-side-borders-greyout {
  border-left: 1px solid darkgray;
  border-right: 1px solid darkgray;
}

.element-race-separator {
  margin-top: 13px;
}

.element-bottom-border {
  border-bottom: 1px solid $borderColor;
}

.element-bottom-border-greyout {
  border-bottom: 1px solid darkgray;
}
