.travellers__table {
  display: flex;
}

.travellers__table-row--dashed {
  flex: 1;
  border-bottom: 1px dashed black;
}

.travellers__body {
  padding-bottom: 20px;
}

.travellers__text {
  margin: 10px 0;
}
