@keyframes Gallop {
  from {
    font-variation-settings: 'TIME' 0;
  }
  to {
    font-variation-settings: 'TIME' 1000;
  }
}

.horse__container {
  font: 140px Muybridge;
  animation: 0.6s linear infinite Gallop;
}
