@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';
@import './src/styles/helpers.scss';

.top-speed__parent {
  column-rule: 1px solid $borderColor;
  flex-direction: column;
  column-fill: auto;
  columns: 2;
  flex: 1;
}

.top-speed__title {
  border-bottom: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2px 0;
  display: flex;
  margin: 2px 0;
}

.top-speed__section {
  padding-bottom: 5px;
}

.top-speed__rating {
  text-align: right;
  min-width: 18px;
}
