.off-countdown--container {
  margin-left: -10px;
  width: auto;
  color: white;
  font-size: 17px;
  font-weight: bolder;
}
.off-countdown--animation {
  animation: blink-fade 1.5s infinite;
  animation-timing-function: linear;
}

@keyframes blink-fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
