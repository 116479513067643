@import './src/styles/helpers.scss';
@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.course-info--vertical-spacing {
  margin: 5px 0;
}

.course-info--distance {
  width: 70px;
}

.course-info--record-holder {
  width: 125px;
}

.course-info--record {
  width: 70px;
}

.course-info__image {
  height: 100%;
  width: 100%;
}

.course-info--average-time {
  width: 50px;
}

.course-info--course-record {
  width: 100px;
}
