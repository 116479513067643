@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';
@import './src/styles/vars.scss';

.header__container {
  display: flex;
  position: absolute;
  height: $headerHeight;
  color: $white;
  align-items: center;
  font-weight: bolder;
  font-size: 60px;
  padding: 10px;
  justify-content: space-between;
  font-family: $proximaBold;
}

.header__meeting {
  display: flex;
  width: 100%;
  align-items: center;
}

.header-name--continued {
  font-size: 2rem;
  width: 75%;
}

.header__meeting-info {
  margin-top: 37px;
  margin-left: 10px;
}

.header__race-info {
  display: flex;
  margin-top: -8px;
}

.header__weather {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  text-transform: none;
}

.header__going {
  margin-top: 20px;
  margin-right: 20px;
}
.header__temperature {
  margin-left: 10px;
  font-family: $primaryFont;
  padding-top: 5px;
}
.header__weather--img {
  width: 90px;
  height: 90px;
}

.header__logo {
  height: 35px;
}

.header--border {
  margin-left: 0;
}

.header--continued {
  font-size: 20px;
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.header--small {
  width: 80%;
}

.header--continued {
  border-top-left-radius: 0px;
}

.header-continuing-next-screen {
  border-top-right-radius: 0px;
}

.header-continued-prev-screen {
  border-top-left-radius: 0px;
}
