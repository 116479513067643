.network-connection {
  position: absolute;
  top: 0;
  left: 34%;
  background-color: red;
  padding: 20px;
  font-size: 25px;
  color: white;
  z-index: 3;
  font-weight: bolder;
}
