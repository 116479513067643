@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.race-card__footer {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  justify-content: space-between;
  padding: 13px;
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
}

.race-card__footer--flex {
  display: flex;
}
