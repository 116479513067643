// Colours
$backgroundColor: #fff;
$racingPostRed: #ed1a23;
$lightGray: lightgray;
$headerColor: #0f5b31;
$titleColor: #002699;
$borderColor: #000;
$white: #fff;
$black: #000;
$grey: grey;
$red: red;
$green: #2bc500;
$highlight: #f0eff1;
$tipping: #fbf1f1;
$oddboxRed: #d11f25;
$ghBlue: #60abdd;
$ghLightBlue: #859ecd;
$cardUnderlineColor: #c9c9c9;

:export {
  racingPostRed: $racingPostRed;
  tippingBackground: $tipping;
  williamHillBlue: #041338;
  coralBlue: #004696;
  oddBoxRed: #d11f25;
}
