@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.race-card__jockey-list {
  list-style: none;
  margin-top: 4px;
}

.race-card--non-runner {
  opacity: 0.5;
  align-items: center;
}

.race-card__plus-10-container {
  background-color: #076cab;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 2px;
  padding: 1px;
  height: 12px;
}

.race-card--flex {
  display: flex;
}

.race-card--flex-between {
  justify-content: space-between;
}

.race-card--flex-align-end {
  align-items: flex-end;
}

.race-card--flex-align-start {
  align-items: flex-start;
}

.race-card--flex-col {
  flex-direction: column;
}

.race-card--flex-one {
  flex: 1;
}

.race-card--flex-centre {
  align-items: center;
}

.race-card--align-right {
  text-align: right;
}

.race-card__row {
  padding: 7px 0 0 0;
  max-height: 300px;
  display: flex;
  width: 100%;
}

.race-card--max-width {
  max-width: 95%;
}

.race-card--min-height {
  min-height: 18px;
}

.race-card--font-med {
  font-size: 15px;
  font-family: $proximaMedium;
  b {
    font-weight: 100;
  }
}

.race-card--vertical-spacing {
  margin-top: 10px;
}

// Silk Column ----------------------------------------------------
.race-card__left {
  flex-direction: column;
  position: relative;
  display: flex;
}

.race-card__silk {
  height: 62px;
  margin: auto 0 auto 0;
}

.race-card__1_place {
  height: 50px;
  width: 50px;
  border: 5px solid#FFD700;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: $proximaCondExtraBold;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.race-card__2_place {
  height: 50px;
  width: 50px;
  border: 5px solid #c0c0c0;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: $proximaCondExtraBold;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.race-card__3_place {
  height: 50px;
  width: 50px;
  border: 5px solid #cd7f32;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: $proximaCondExtraBold;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.race-card__place {
  height: 50px;
  width: 50px;
  border: 5px solid black;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: $proximaCondExtraBold;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
}

.race-card__start-number {
  max-height: 32px;
}

// Name ----------------------------------------------------
.race-card__right {
  justify-content: space-between;
  display: flex;
  flex-grow: 1;
}

.race-card__start-draw {
  width: 60px;
  margin: 0 4px;
  position: relative;
}

.race-card__name-container {
  flex-direction: column;
  max-width: 190px;
  display: flex;
  flex: 1;
  position: relative;
}

.race-card__name {
  display: flex;
  width: 280px;
}

// Owner and Trainer ----------------------------------------------------

.race-card__jockey {
  font-size: 12px;
  font-family: $proximaCondExtraBold;
  padding-top: 4px;
}

.race-card__owner-trainer {
  align-items: flex-end;
}

//Owner --------------------------

.race-card__owner {
  margin-bottom: -1px;
}

// Weight and stats-------------------------------

.race-card-weight {
  justify-content: flex-end;
}

.race-card__weight-stats-container {
  display: flex;
  width: 80px;
  justify-content: space-between;
}

// Stats -----------------------------------------------------

.race-card__stats-container {
  display: flex;
  flex-wrap: wrap;
}

.race-card__weight-stats-odds-wrapper {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: relative;
  flex: 1;
}

.race-card__rating {
  border: 1px solid $borderColor;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding: 0.5rem;
  display: flex;
  height: 23px;
  width: 23px;
  position: absolute;
  bottom: 2px;
  right: 0;

  p {
    padding-top: 0;
    font-weight: 600;
  }
}

.race-card__rating--improved {
  background-color: $borderColor;
  color: $white;
}

.race-card__highest {
  background-color: $borderColor;
  color: $white;
}

.race-card__arrow {
  transform: translate(-50%, -33%);
  position: absolute;
  height: 10px;
  width: 9px;
  left: 50%;
  top: -35%;
}

.race-card__price {
  font-size: 15px;
}

.race-card__price-container {
  text-align: center;
  margin-top: 5px;
  display: flex;
}

.race-card__price-container-non-runner {
  text-align: center;
  margin-left: 5px;
  position: absolute;
  bottom: 16px;
  right: 0;
}

.race-card__price-container-favourite {
  background-color: #029f01;
  height: 28px;
  padding: 5px;
  font-size: 21px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 3px;
  color: white;
  font-weight: bold;
  font-family: $proximaCondBold;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.race-card__price-odd {
  height: 28px;
  padding: 2px;
  min-width: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.race-card__starting-price-container {
  padding: 5px;
  font-weight: bolder;
}

.race-card__price-history-arrow {
  width: 0px;
  height: 0px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.race-card__higher-arrow {
  border-left: 4px solid $red;
}

.race-card__lower-arrow {
  border-left: 4px solid $green;
}

.race-card__neutral-arrow {
  border-left: 4px solid $black;
}

.race-card__price-history {
  display: flex;
  align-items: center;
}

.race-card__price-history-container {
  display: flex;
  font-weight: bold;
  margin-top: 3px;
}

.race-card__age-weight {
  display: flex;
}

.race-card__non-runner-container {
  display: flex;
}

.race-card__tipped--horse {
  background: $tipping;
  z-index: -2;
  border-style: solid;
  margin-left: -13px;
  margin-right: -13px;
  margin-top: 1px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 7px;
}

.race-card__first--horse {
  border-top: none;
  padding-top: 10px;
}

.race-card__weight-handicap {
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 15px;
}
