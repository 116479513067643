@import './src/styles/fonts.scss';
@import './src/styles/colors.scss';

// Colour
.text--black {
  color: $black;
}

.text--white {
  color: $white;
}

.text--red {
  color: $racingPostRed;
}

.text--grey {
  color: $grey;
}

// Size
.text--extraSmall {
  font-size: 8px;
}

.text--smallest {
  font-size: 10px;
}

.text--smaller {
  font-size: 14px;
}

.text--info {
  font-size: 13px;
}

.text--small {
  padding-top: -4px;
  font-size: 12px;
}

.text--normal {
  font-size: 15px;
}

.text--forecast {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
}

.text--rating {
  font-size: 16px;
  letter-spacing: 0;
}

.text--raceboard {
  font-size: 17px;
}

.text--medium {
  font-size: 19px;
}

.text--smedium {
  font-size: 21px;
}

.text--xmedium {
  font-size: 22px;
}

.text--big {
  font-size: 30px;
}

.text--large {
  font-size: 35px;
}

.text--giant {
  font-size: 50px;
}

.text--header {
  font-size: 82px;
  padding-top: 5px;
}

.text--description {
  font-size: 40px;
}

// Style
.text--upper {
  text-transform: uppercase;
}

.text--lower {
  text-transform: lowercase;
}

// Weight
.text--bold {
  font-weight: bold;
}

.text--norm {
  font-weight: normal;
}

.text--light {
  font-weight: lighter;
}

.text--italic {
  font-style: italic;
}

.text--italic-custom {
  transform: skew(-10deg);
}

.text--flex {
  display: flex;
}

.text--flex-one {
  flex: 1;
}

.text--max-width {
  width: 100%;
}

.text--dotted-border {
  &:after {
    align-self: flex-end;
    content: '';
    flex: 1 1;
    background-image: radial-gradient(circle, black 0.7px, transparent 0.5px);
    background-repeat: repeat-x;
    background-size: 2px 1px;
    height: 1px;
    left: 0;
    right: 0;
    position: relative;
    bottom: 2px;
  }
}

.text--ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Misc
.text--space {
  padding-right: 2px;
}

.text--space-large {
  padding: 0 5px;
}

.text--no-space {
  padding-right: 0;
}

.text--spacing {
  padding: 0 3px;
}

.text--preText {
  height: 100%;
  vertical-align: text-top;
}

.text--highlight {
  background-color: $black;
  color: $white;
}

.text--inline {
  display: inline;
}

.text--super {
  vertical-align: top;
}

.text--centre {
  text-align: center;
}

.text--rating-border {
  border: 1px solid $borderColor;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  display: flex;
  height: 30px;
  padding: 1px;
  width: 30px;
}

.text--white-space-fix {
  padding-top: 3px;
  line-height: 0.6;
}

.text--font-primary {
  font-family: $primaryFont;
}
.text--font-secondary {
  font-family: $secondaryFont;
}

.text--font-tertiary {
  font-family: $tertiaryFont;
}

.text--times-roman {
  font-family: $timesRoman;
}

.text--font-bold {
  font-family: $proximaBold;
}

.text--swis-black {
  font-family: $swisBlack;
}

.text--proxima-black {
  font-family: $proximaCondExtraBold;
}

.text--super--alignment {
  vertical-align: 34px;
}
.text--proxima-cond-medium {
  font-family: $proximaCondMedium;
}

.text--proxima-medium {
  font-family: $proximaMedium;
}

.text--proxima-cond-semi-bold {
  font-family: $proximaCondSemiBold;
}

.text--proxima-cond-semi-bold-italic {
  font-family: $proximaCondSemiBoldIt;
}

.text--proxima-cond-bold {
  font-family: $proximaCondBold;
}

.text--proxima-extra-bold {
  font-family: $proximaExtraBold;
}

.text--proxima-medium {
  font-family: $proximaMedium;
}
.text--letter-spacing {
  letter-spacing: -0.25px;
}

.text--space-left {
  padding-left: 2px;
}

.text--nowrap {
  white-space: nowrap;
}
