@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.verdict__title {
  font-family: $proximaBold;
  text-transform: uppercase;
  font-size: 25px;
  margin-right: 5px;
  font-weight: 900;
}

.verdict__body {
  font-family: $proximaCondMedium;
  padding: 13px;
  padding-bottom: 12px;
  line-height: 18px;
  margin-left: -7px;
  margin-right: -7px;
  line-height: 21px;
}

.verdict__body--img {
  width: 237px;
  margin-bottom: 5px;
  height: 15px;
}

.verdict--horse-highlight {
  display: block;

  b {
    font-family: $proximaBold;
    font-weight: normal;
  }
}
