.console-debug {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  width: 600px;
  overflow: scroll;
  background-color: black;
  z-index: 10;
}
