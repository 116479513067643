@import './src/styles/colors.scss';

.no-runners--container {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: $lightGray;
  margin-left: -7px;
  margin-right: -7px;
  font-size: 20px;
  background-image: repeating-linear-gradient(
      135deg,
      $lightGray,
      rgba(255, 255, 255, 0.3) 9px,
      rgba(255, 255, 255, 0.3) 10px
    ),
    repeating-linear-gradient(
      45deg,
      $lightGray,
      rgba(255, 255, 255, 0.3) 9px,
      rgba(255, 255, 255, 0.3) 10px
    );
}
