@import './colors.scss';

// Variables
$helpers-small: 5px;
$helpers-medium: 7px;
$helpers-large: 10px;

// Align
.helpers--align-right {
  text-align: right;
}

.helpers--align-left {
  text-align: left;
}

.helpers--align-centre {
  align-items: center;
}

.helpers--align-end {
  align-items: flex-end;
}

// Border
.helpers--border-top {
  border-top: 1px solid $borderColor;
  text-align: left;
}

.helpers--border-bottom {
  border-bottom: 1px solid $borderColor;
}

.helpers--border-right {
  border-right: 1px solid $borderColor;
}

.helpers--border-left {
  border-left: 1px solid $borderColor;
}

// Flex box
.helpers--flex {
  display: flex;
}

.helpers--flex-col {
  flex-direction: column;
  display: flex;
}

.helpers--flex-between {
  justify-content: space-between;
}

.helpers--flex-one {
  flex: 1;
}

// Table
.helpers--table-head {
  border-bottom: 1px solid $borderColor;
  vertical-align: top;
  tr {
    th:nth-child(4) {
      text-align: right;
    }
  }
}

// Padding
.helpers--padding-horizontal-small {
  padding-right: $helpers-small;
  padding-left: $helpers-small;
}

.helpers--padding-right-large {
  padding-right: $helpers-large;
}

.helpers--padding-left-large {
  padding-left: $helpers-large;
}

.helpers--padding-left-medium {
  padding-left: $helpers-medium;
}

.helpers--padding-top-small {
  padding-top: $helpers-small;
}

.helpers--padding-top-medium {
  padding-top: $helpers-medium;
}

.helpers--padding-bottom-medium {
  padding-bottom: $helpers-medium;
}

.helpers--padding-left-medium {
  padding-left: $helpers-medium;
}

.helpers--header-padding {
  thead {
    tr {
      th {
        padding: 10px 0;
      }
    }
  }
}

.helpers--body-padding {
  tbody {
    tr {
      td {
        padding-top: 5px;
      }
    }
  }
}

// Display

.helpers--display-table {
  display: table;
}
