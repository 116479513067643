// Global Table Styles
@import './colors.scss';

// Table
.table-container {
  width: 100%;
}

.table__bottomBorder {
  border-bottom: 1px solid $borderColor;
}

.table-title--largest {
  width: 80%;
}

.table-title--large {
  width: 60%;
}

.table-title--medium {
  width: 40%;
}

.table-title--small {
  width: 20%;
}

.table-title--smallest {
  width: 10%;
}

// Footer
.table__footer {
  font-size: 10px;
}

// Misc
.table--normal {
  font-weight: normal;
}

.table--bold {
  font-weight: bold;
}

.table--italic {
  font-style: italic;
}

.table--left {
  text-align: left;
}

.table--right {
  text-align: right;
}

.table--center {
  text-align: center;
}

.table__cell-flex {
  display: flex;
}

.table-border--left {
  border-left: 1px solid $borderColor;
  padding-left: 0.5rem;
}

.table-border--right {
  border-right: 1px solid $borderColor;
  padding-right: 0.5rem;
}

// Custom Table Styles
.jockey-trainer--wins {
  width: 20px;
  text-align: right;
  font-weight: bold;
}

.jockey-trainer--rides {
  width: 20px;
  text-align: left;
}

.jockey-trainer--percent {
  width: 30%;
  text-align: right;
}
