@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.betting-forecast {
  padding: 13px;
  padding-top: 12px;
  margin-left: -7px;
  margin-right: -7px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  border-top: 0;
  margin-bottom: -1px;
}
