.socket-connection {
  position: absolute;
  top: 0;
  left: 37%;
  background-color: yellow;
  padding: 20px;
  font-size: 25px;
  color: black;
  font-weight: bolder;
  z-index: 2;
}
