

.table-container {
  width: 100%; }

.table__bottomBorder {
  border-bottom: 1px solid #000; }

.table-title--largest {
  width: 80%; }

.table-title--large {
  width: 60%; }

.table-title--medium {
  width: 40%; }

.table-title--small {
  width: 20%; }

.table-title--smallest {
  width: 10%; }

.table__footer {
  font-size: 10px; }

.table--normal {
  font-weight: normal; }

.table--bold {
  font-weight: bold; }

.table--italic {
  font-style: italic; }

.table--left {
  text-align: left; }

.table--right {
  text-align: right; }

.table--center {
  text-align: center; }

.table__cell-flex {
  display: flex; }

.table-border--left {
  border-left: 1px solid #000;
  padding-left: 0.5rem; }

.table-border--right {
  border-right: 1px solid #000;
  padding-right: 0.5rem; }

.jockey-trainer--wins {
  width: 20px;
  text-align: right;
  font-weight: bold; }

.jockey-trainer--rides {
  width: 20px;
  text-align: left; }

.jockey-trainer--percent {
  width: 30%;
  text-align: right; }

.top-draw__section {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0.25rem;
  width: 100%; }

.top-draw__list {
  list-style-type: disc;
  list-style-position: inside; }

.text--black {
  color: #000; }

.text--white {
  color: #fff; }

.text--red {
  color: #ed1a23; }

.text--grey {
  color: grey; }

.text--extraSmall {
  font-size: 8px; }

.text--smallest {
  font-size: 10px; }

.text--smaller {
  font-size: 14px; }

.text--info {
  font-size: 13px; }

.text--small {
  padding-top: -4px;
  font-size: 12px; }

.text--normal {
  font-size: 15px; }

.text--forecast {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0; }

.text--rating {
  font-size: 16px;
  letter-spacing: 0; }

.text--raceboard {
  font-size: 17px; }

.text--medium {
  font-size: 19px; }

.text--smedium {
  font-size: 21px; }

.text--xmedium {
  font-size: 22px; }

.text--big {
  font-size: 30px; }

.text--large {
  font-size: 35px; }

.text--giant {
  font-size: 50px; }

.text--header {
  font-size: 82px;
  padding-top: 5px; }

.text--description {
  font-size: 40px; }

.text--upper {
  text-transform: uppercase; }

.text--lower {
  text-transform: lowercase; }

.text--bold {
  font-weight: bold; }

.text--norm {
  font-weight: normal; }

.text--light {
  font-weight: lighter; }

.text--italic {
  font-style: italic; }

.text--italic-custom {
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg); }

.text--flex {
  display: flex; }

.text--flex-one {
  flex: 1 1; }

.text--max-width {
  width: 100%; }

.text--dotted-border:after {
  align-self: flex-end;
  content: '';
  flex: 1 1;
  background-image: radial-gradient(circle, black 0.7px, transparent 0.5px);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  height: 1px;
  left: 0;
  right: 0;
  position: relative;
  bottom: 2px; }

.text--ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.text--space {
  padding-right: 2px; }

.text--space-large {
  padding: 0 5px; }

.text--no-space {
  padding-right: 0; }

.text--spacing {
  padding: 0 3px; }

.text--preText {
  height: 100%;
  vertical-align: text-top; }

.text--highlight {
  background-color: #000;
  color: #fff; }

.text--inline {
  display: inline; }

.text--super {
  vertical-align: top; }

.text--centre {
  text-align: center; }

.text--rating-border {
  border: 1px solid #000;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  display: flex;
  height: 30px;
  padding: 1px;
  width: 30px; }

.text--white-space-fix {
  padding-top: 3px;
  line-height: 0.6; }

.text--font-primary {
  font-family: "Proxima Nova", Georgia, "Times New Roman", Times, serif; }

.text--font-secondary {
  font-family: "Swis721BT", arial; }

.text--font-tertiary {
  font-family: "Proxima Nova Cond", arial; }

.text--times-roman {
  font-family: "Times New Roman"; }

.text--font-bold {
  font-family: "Proxima Nova Bold"; }

.text--swis-black {
  font-family: "Swis721BT Black"; }

.text--proxima-black {
  font-family: "Proxima Nova Cond Extra Bold"; }

.text--super--alignment {
  vertical-align: 34px; }

.text--proxima-cond-medium {
  font-family: "Proxima Nova Cond Medium"; }

.text--proxima-medium {
  font-family: "Proxima Nova Medium"; }

.text--proxima-cond-semi-bold {
  font-family: "Proxima Nova Cond Semi Bold"; }

.text--proxima-cond-semi-bold-italic {
  font-family: "Proxima Nova Cond Semi Bold Italic"; }

.text--proxima-cond-bold {
  font-family: "Proxima Nova Cond Bold"; }

.text--proxima-extra-bold {
  font-family: "Proxima Nova Extra Bold"; }

.text--proxima-medium {
  font-family: "Proxima Nova Medium"; }

.text--letter-spacing {
  letter-spacing: -0.25px; }

.text--space-left {
  padding-left: 2px; }

.text--nowrap {
  white-space: nowrap; }

.helpers--align-right {
  text-align: right; }

.helpers--align-left {
  text-align: left; }

.helpers--align-centre {
  align-items: center; }

.helpers--align-end {
  align-items: flex-end; }

.helpers--border-top {
  border-top: 1px solid #000;
  text-align: left; }

.helpers--border-bottom {
  border-bottom: 1px solid #000; }

.helpers--border-right {
  border-right: 1px solid #000; }

.helpers--border-left {
  border-left: 1px solid #000; }

.helpers--flex {
  display: flex; }

.helpers--flex-col {
  flex-direction: column;
  display: flex; }

.helpers--flex-between {
  justify-content: space-between; }

.helpers--flex-one {
  flex: 1 1; }

.helpers--table-head {
  border-bottom: 1px solid #000;
  vertical-align: top; }
  .helpers--table-head tr th:nth-child(4) {
    text-align: right; }

.helpers--padding-horizontal-small {
  padding-right: 5px;
  padding-left: 5px; }

.helpers--padding-right-large {
  padding-right: 10px; }

.helpers--padding-left-large {
  padding-left: 10px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--padding-top-small {
  padding-top: 5px; }

.helpers--padding-top-medium {
  padding-top: 7px; }

.helpers--padding-bottom-medium {
  padding-bottom: 7px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--header-padding thead tr th {
  padding: 10px 0; }

.helpers--body-padding tbody tr td {
  padding-top: 5px; }

.helpers--display-table {
  display: table; }

.top-speed__parent {
  -webkit-column-rule: 1px solid #000;
     -moz-column-rule: 1px solid #000;
          column-rule: 1px solid #000;
  flex-direction: column;
  -webkit-column-fill: auto;
     -moz-column-fill: auto;
          column-fill: auto;
  -webkit-columns: 2;
     -moz-columns: 2;
          columns: 2;
  flex: 1 1; }

.top-speed__title {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2px 0;
  display: flex;
  margin: 2px 0; }

.top-speed__section {
  padding-bottom: 5px; }

.top-speed__rating {
  text-align: right;
  min-width: 18px; }

.helpers--align-right {
  text-align: right; }

.helpers--align-left {
  text-align: left; }

.helpers--align-centre {
  align-items: center; }

.helpers--align-end {
  align-items: flex-end; }

.helpers--border-top {
  border-top: 1px solid #000;
  text-align: left; }

.helpers--border-bottom {
  border-bottom: 1px solid #000; }

.helpers--border-right {
  border-right: 1px solid #000; }

.helpers--border-left {
  border-left: 1px solid #000; }

.helpers--flex {
  display: flex; }

.helpers--flex-col {
  flex-direction: column;
  display: flex; }

.helpers--flex-between {
  justify-content: space-between; }

.helpers--flex-one {
  flex: 1 1; }

.helpers--table-head {
  border-bottom: 1px solid #000;
  vertical-align: top; }
  .helpers--table-head tr th:nth-child(4) {
    text-align: right; }

.helpers--padding-horizontal-small {
  padding-right: 5px;
  padding-left: 5px; }

.helpers--padding-right-large {
  padding-right: 10px; }

.helpers--padding-left-large {
  padding-left: 10px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--padding-top-small {
  padding-top: 5px; }

.helpers--padding-top-medium {
  padding-top: 7px; }

.helpers--padding-bottom-medium {
  padding-bottom: 7px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--header-padding thead tr th {
  padding: 10px 0; }

.helpers--body-padding tbody tr td {
  padding-top: 5px; }

.helpers--display-table {
  display: table; }

.course-info--vertical-spacing {
  margin: 5px 0; }

.course-info--distance {
  width: 70px; }

.course-info--record-holder {
  width: 125px; }

.course-info--record {
  width: 70px; }

.course-info__image {
  height: 100%;
  width: 100%; }

.course-info--average-time {
  width: 50px; }

.course-info--course-record {
  width: 100px; }

.table-container {
  width: 100%; }

.table__bottomBorder {
  border-bottom: 1px solid #000; }

.table-title--largest {
  width: 80%; }

.table-title--large {
  width: 60%; }

.table-title--medium {
  width: 40%; }

.table-title--small {
  width: 20%; }

.table-title--smallest {
  width: 10%; }

.table__footer {
  font-size: 10px; }

.table--normal {
  font-weight: normal; }

.table--bold {
  font-weight: bold; }

.table--italic {
  font-style: italic; }

.table--left {
  text-align: left; }

.table--right {
  text-align: right; }

.table--center {
  text-align: center; }

.table__cell-flex {
  display: flex; }

.table-border--left {
  border-left: 1px solid #000;
  padding-left: 0.5rem; }

.table-border--right {
  border-right: 1px solid #000;
  padding-right: 0.5rem; }

.jockey-trainer--wins {
  width: 20px;
  text-align: right;
  font-weight: bold; }

.jockey-trainer--rides {
  width: 20px;
  text-align: left; }

.jockey-trainer--percent {
  width: 30%;
  text-align: right; }

.top-jockey__container {
  font-size: 0.7rem;
  overflow: visible;
  overflow: initial; }

.top-trainers--flex {
  display: flex; }

.top-trainers--flex-one {
  flex: 1 1; }

.top-trainers--flex-half {
  flex: 0.5 1; }

.top-trainers--align-end {
  align-items: flex-end; }

.top-trainers--flex-between {
  justify-content: space-between; }

.top-trainers--flex-col {
  flex-direction: column; }

.top-trainers__container {
  flex-direction: column;
  display: flex; }

.top-trainers__header {
  border-bottom: 1px solid black; }

.top-trainers__wins-rides-percent {
  min-width: 12%;
  padding-top: 5px; }

.top-trainers__percent {
  text-align: right;
  min-width: 20px; }

.top-trainers__jockey-trainer-name {
  justify-content: space-between;
  min-width: 50%; }

.top-trainers__trainer-name {
  min-width: 25%; }

.top-trainers--align-left {
  text-align: left; }

.top-trainers--align-right {
  text-align: right; }

.top-trainers--align-centre {
  text-align: center; }

.top-trainers__stake {
  text-align: right;
  min-width: 28px; }

.top-trainers__row {
  justify-content: space-between;
  align-items: flex-end;
  display: flex; }

.top-trainers--border-left {
  border-left: 1px solid black; }

.top-trainers__rides-since {
  text-align: center;
  min-width: 15%;
  padding-left: 10px;
  padding-top: 5px; }

.top-trainers__wins {
  min-width: 20px; }

.top-trainers--flex-quarter {
  flex: 0.15 1; }

.top-trainers--padding-bottom {
  padding-bottom: 10px; }

.table-container {
  width: 100%; }

.table__bottomBorder {
  border-bottom: 1px solid #000; }

.table-title--largest {
  width: 80%; }

.table-title--large {
  width: 60%; }

.table-title--medium {
  width: 40%; }

.table-title--small {
  width: 20%; }

.table-title--smallest {
  width: 10%; }

.table__footer {
  font-size: 10px; }

.table--normal {
  font-weight: normal; }

.table--bold {
  font-weight: bold; }

.table--italic {
  font-style: italic; }

.table--left {
  text-align: left; }

.table--right {
  text-align: right; }

.table--center {
  text-align: center; }

.table__cell-flex {
  display: flex; }

.table-border--left {
  border-left: 1px solid #000;
  padding-left: 0.5rem; }

.table-border--right {
  border-right: 1px solid #000;
  padding-right: 0.5rem; }

.jockey-trainer--wins {
  width: 20px;
  text-align: right;
  font-weight: bold; }

.jockey-trainer--rides {
  width: 20px;
  text-align: left; }

.jockey-trainer--percent {
  width: 30%;
  text-align: right; }

.top-jockey__container {
  font-size: 0.7rem;
  overflow: visible;
  overflow: initial; }

.top-jockeys--flex {
  display: flex; }

.top-jockeys--flex-one {
  flex: 1 1; }

.top-jockeys--flex-half {
  flex: 0.5 1; }

.top-jockeys--flex-quarter {
  flex: 0.25 1; }

.top-jockeys--align-end {
  align-items: flex-end; }

.top-jockeys--flex-between {
  justify-content: space-between; }

.top-jockeys--flex-col {
  flex-direction: column; }

.top-jockeys__container {
  flex-direction: column;
  display: flex; }

.top-jockeys--align-right {
  text-align: right; }

.top-jockeys--align-left {
  text-align: left; }

.top-jockeys__header {
  border-bottom: 1px solid black;
  padding-bottom: 10px; }

.top-jockeys--border-left {
  border-left: 1px solid black; }

.top-jockeys__row {
  justify-content: space-between;
  align-items: flex-end;
  display: flex; }

.top-jockeys__wins-rides-percent {
  min-width: 15%; }

.top-jockeys__percent {
  text-align: right;
  min-width: 24px; }

.top-jockeys__jockey-trainer-name {
  justify-content: space-between;
  width: 75%; }

.top-jockeys__jockey-trainer-name-header {
  width: 75%; }

.top-jockeys__stake {
  padding: 1px 5px 1px 5px;
  min-width: 9%; }

.top-jockeys__rides-since {
  min-width: 5%; }

.top-jockeys__wins {
  min-width: 20px; }

.helpers--align-right {
  text-align: right; }

.helpers--align-left {
  text-align: left; }

.helpers--align-centre {
  align-items: center; }

.helpers--align-end {
  align-items: flex-end; }

.helpers--border-top {
  border-top: 1px solid #000;
  text-align: left; }

.helpers--border-bottom {
  border-bottom: 1px solid #000; }

.helpers--border-right {
  border-right: 1px solid #000; }

.helpers--border-left {
  border-left: 1px solid #000; }

.helpers--flex {
  display: flex; }

.helpers--flex-col {
  flex-direction: column;
  display: flex; }

.helpers--flex-between {
  justify-content: space-between; }

.helpers--flex-one {
  flex: 1 1; }

.helpers--table-head {
  border-bottom: 1px solid #000;
  vertical-align: top; }
  .helpers--table-head tr th:nth-child(4) {
    text-align: right; }

.helpers--padding-horizontal-small {
  padding-right: 5px;
  padding-left: 5px; }

.helpers--padding-right-large {
  padding-right: 10px; }

.helpers--padding-left-large {
  padding-left: 10px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--padding-top-small {
  padding-top: 5px; }

.helpers--padding-top-medium {
  padding-top: 7px; }

.helpers--padding-bottom-medium {
  padding-bottom: 7px; }

.helpers--padding-left-medium {
  padding-left: 7px; }

.helpers--header-padding thead tr th {
  padding: 10px 0; }

.helpers--body-padding tbody tr td {
  padding-top: 5px; }

.helpers--display-table {
  display: table; }

.favourites__year {
  min-width: 120px;
  vertical-align: top; }

.favourites__wins-rides-percentage {
  min-width: 30px; }

.favourites__stake {
  min-width: 45px; }

.ten-year__table {
  font-family: "Proxima Nova Cond", arial;
  -webkit-columns: 2;
     -moz-columns: 2;
          columns: 2; }
  .ten-year__table td:not(:first-child) {
    text-align: right; }

.ten-year__table-item {
  border-bottom: 5px solid black;
  margin-bottom: 5px;
  padding-bottom: 5px; }

.ten-year__table--border {
  border-bottom: 1px solid black;
  border-top: 1px solid black; }
  .ten-year__table--border td {
    padding-top: 4px;
    padding-bottom: 4px; }

.ten-year__table--border-bottom {
  border-bottom: 1px solid black; }

.ten-year__table--border-top {
  border-top: 1px solid black; }

.ten-year__table--main-title {
  font-weight: bold; }
  .ten-year__table--main-title td:not(:first-child) {
    font-style: italic; }

.ten-year__table--sub-title {
  font-style: italic; }
  .ten-year__table--sub-title td {
    padding-top: 4px; }

.ten-year__table--bottom-pad td {
  padding-bottom: 4px; }

.travellers__table {
  display: flex; }

.travellers__table-row--dashed {
  flex: 1 1;
  border-bottom: 1px dashed black; }

.travellers__body {
  padding-bottom: 20px; }

.travellers__text {
  margin: 10px 0; }

.spotlight__title-container {
  align-items: center;
  padding-bottom: 10px;
  padding-top: 15px;
  display: flex; }

.spotlight__logo {
  width: 250px;
  margin-right: 8px;
  height: 15px; }

.spotlight__horse-name {
  margin-right: 10px;
  font-weight: bold; }

.spotlight__indent-container {
  padding-left: 22px;
  text-indent: -22px;
  line-height: 17px;
  padding-bottom: 2px;
  padding-top: 2px; }

.spotlight_container {
  overflow: hidden;
  padding-left: 7px;
  padding-right: 7px; }

.spotlight__inline-spotlight {
  line-height: 1;
  padding-top: 2px; }

.spotlight_container-no-rpr {
  margin-bottom: 15px !important; }

.spotlight__non-runner p {
  opacity: 0.5; }



.verdict__title {
  font-family: "Proxima Nova Bold";
  text-transform: uppercase;
  font-size: 25px;
  margin-right: 5px;
  font-weight: 900; }

.verdict__body {
  font-family: "Proxima Nova Cond Medium";
  padding: 13px;
  padding-bottom: 12px;
  line-height: 18px;
  margin-left: -7px;
  margin-right: -7px;
  line-height: 21px; }

.verdict__body--img {
  width: 237px;
  margin-bottom: 5px;
  height: 15px; }

.verdict--horse-highlight {
  display: block; }
  .verdict--horse-highlight b {
    font-family: "Proxima Nova Bold";
    font-weight: normal; }

.race-card__jockey-list {
  list-style: none;
  margin-top: 4px; }

.race-card--non-runner {
  opacity: 0.5;
  align-items: center; }

.race-card__plus-10-container {
  background-color: #076cab;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 2px;
  padding: 1px;
  height: 12px; }

.race-card--flex {
  display: flex; }

.race-card--flex-between {
  justify-content: space-between; }

.race-card--flex-align-end {
  align-items: flex-end; }

.race-card--flex-align-start {
  align-items: flex-start; }

.race-card--flex-col {
  flex-direction: column; }

.race-card--flex-one {
  flex: 1 1; }

.race-card--flex-centre {
  align-items: center; }

.race-card--align-right {
  text-align: right; }

.race-card__row {
  padding: 7px 0 0 0;
  max-height: 300px;
  display: flex;
  width: 100%; }

.race-card--max-width {
  max-width: 95%; }

.race-card--min-height {
  min-height: 18px; }

.race-card--font-med {
  font-size: 15px;
  font-family: "Proxima Nova Medium"; }
  .race-card--font-med b {
    font-weight: 100; }

.race-card--vertical-spacing {
  margin-top: 10px; }

.race-card__left {
  flex-direction: column;
  position: relative;
  display: flex; }

.race-card__silk {
  height: 62px;
  margin: auto 0 auto 0; }

.race-card__1_place {
  height: 50px;
  width: 50px;
  border: 5px solid #FFD700;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Proxima Nova Cond Extra Bold";
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px; }

.race-card__2_place {
  height: 50px;
  width: 50px;
  border: 5px solid #c0c0c0;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Proxima Nova Cond Extra Bold";
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px; }

.race-card__3_place {
  height: 50px;
  width: 50px;
  border: 5px solid #cd7f32;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Proxima Nova Cond Extra Bold";
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px; }

.race-card__place {
  height: 50px;
  width: 50px;
  border: 5px solid black;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Proxima Nova Cond Extra Bold";
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px; }

.race-card__start-number {
  max-height: 32px; }

.race-card__right {
  justify-content: space-between;
  display: flex;
  flex-grow: 1; }

.race-card__start-draw {
  width: 60px;
  margin: 0 4px;
  position: relative; }

.race-card__name-container {
  flex-direction: column;
  max-width: 190px;
  display: flex;
  flex: 1 1;
  position: relative; }

.race-card__name {
  display: flex;
  width: 280px; }

.race-card__jockey {
  font-size: 12px;
  font-family: "Proxima Nova Cond Extra Bold";
  padding-top: 4px; }

.race-card__owner-trainer {
  align-items: flex-end; }

.race-card__owner {
  margin-bottom: -1px; }

.race-card-weight {
  justify-content: flex-end; }

.race-card__weight-stats-container {
  display: flex;
  width: 80px;
  justify-content: space-between; }

.race-card__stats-container {
  display: flex;
  flex-wrap: wrap; }

.race-card__weight-stats-odds-wrapper {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: relative;
  flex: 1 1; }

.race-card__rating {
  border: 1px solid #000;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  padding: 0.5rem;
  display: flex;
  height: 23px;
  width: 23px;
  position: absolute;
  bottom: 2px;
  right: 0; }
  .race-card__rating p {
    padding-top: 0;
    font-weight: 600; }

.race-card__rating--improved {
  background-color: #000;
  color: #fff; }

.race-card__highest {
  background-color: #000;
  color: #fff; }

.race-card__arrow {
  -webkit-transform: translate(-50%, -33%);
          transform: translate(-50%, -33%);
  position: absolute;
  height: 10px;
  width: 9px;
  left: 50%;
  top: -35%; }

.race-card__price {
  font-size: 15px; }

.race-card__price-container {
  text-align: center;
  margin-top: 5px;
  display: flex; }

.race-card__price-container-non-runner {
  text-align: center;
  margin-left: 5px;
  position: absolute;
  bottom: 16px;
  right: 0; }

.race-card__price-container-favourite {
  background-color: #029f01;
  height: 28px;
  padding: 5px;
  font-size: 21px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 3px;
  color: white;
  font-weight: bold;
  font-family: "Proxima Nova Cond Bold";
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.race-card__price-odd {
  height: 28px;
  padding: 2px;
  min-width: 69px;
  display: flex;
  align-items: center;
  justify-content: center; }

.race-card__starting-price-container {
  padding: 5px;
  font-weight: bolder; }

.race-card__price-history-arrow {
  width: 0px;
  height: 0px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent; }

.race-card__higher-arrow {
  border-left: 4px solid red; }

.race-card__lower-arrow {
  border-left: 4px solid #2bc500; }

.race-card__neutral-arrow {
  border-left: 4px solid #000; }

.race-card__price-history {
  display: flex;
  align-items: center; }

.race-card__price-history-container {
  display: flex;
  font-weight: bold;
  margin-top: 3px; }

.race-card__age-weight {
  display: flex; }

.race-card__non-runner-container {
  display: flex; }

.race-card__tipped--horse {
  background: #fbf1f1;
  z-index: -2;
  border-style: solid;
  margin-left: -13px;
  margin-right: -13px;
  margin-top: 1px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 7px; }

.race-card__first--horse {
  border-top: none;
  padding-top: 10px; }

.race-card__weight-handicap {
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 15px; }

.raceboard__container {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 2px solid #000;
  margin-left: -7px;
  margin-right: -7px; }

.raceboard__time-info {
  display: flex;
  flex: 1 1;
  align-items: center; }

.raceboard__inner-container {
  justify-content: space-between;
  display: flex;
  padding-bottom: 0;
  flex-wrap: wrap;
  padding: 3px 13px 5px 13px;
  height: 65px; }

.raceboard__race-num {
  font-weight: bolder;
  font-family: "Proxima Nova Extra Bold";
  text-transform: uppercase; }

.raceboard__tv-container {
  justify-content: flex-end;
  display: flex;
  margin: 6px;
  margin-top: 8px;
  margin-right: 0; }

.raceboard__tv-winner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.raceboard__tv {
  display: flex;
  background-color: grey;
  color: #fff;
  align-items: center;
  height: 20px;
  padding: 0px 10px;
  border-radius: 10px; }

.raceboard__tv-logo-container {
  height: 42px; }

.raceboard__distance {
  display: flex;
  justify-content: flex-end; }

.raceboard__info {
  flex: 1 1;
  font-family: "Proxima Nova Cond Medium";
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
  padding-left: 5px;
  padding-bottom: 2px; }

.raceboard__class-winner {
  display: flex; }

.raceboard__race-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  width: 100%;
  padding: 3px 13px 1px 13px;
  font-size: 17px;
  font-family: "Proxima Nova Extra Bold"; }

.off-countdown--container {
  margin-left: -10px;
  width: auto;
  color: white;
  font-size: 17px;
  font-weight: bolder; }

.off-countdown--animation {
  -webkit-animation: blink-fade 1.5s infinite;
          animation: blink-fade 1.5s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

@-webkit-keyframes blink-fade {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes blink-fade {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.tipping__body {
  padding: 0 12px 0 0;
  border-top: 1px solid #ed1c24;
  border-bottom: 1px solid #ed1c24;
  background-color: #fbf1f1;
  font-family: "Proxima Nova Cond", arial;
  font-size: 16px;
  line-height: 16px;
  margin-left: -7px;
  margin-right: -7px;
  min-height: 100px; }

.tipping__title {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 56%;
  height: 25px;
  color: #fff; }
  .tipping__title:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ed1a23;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg); }

.tipping__title-text {
  position: relative;
  top: 25%;
  padding-left: 13px;
  font-family: "Proxima Nova Bold";
  font-size: 17px; }

.sweetspots__text {
  padding: 5px 0 12px 12px; }

.sweetspots__tip-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 20px; }
  .sweetspots__tip-info p {
    margin-left: 10px;
    font-weight: bold; }

.sweetspots__img {
  width: 50px;
  height: 50px; }

.sweetspots__horse-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px; }
  .sweetspots__horse-name p {
    padding: 5px 0px;
    font-family: "Proxima Nova Cond", arial;
    font-weight: bold; }
  .sweetspots__horse-name .sweetspots__race-info {
    font-size: 15px;
    padding: 0; }

.sweetspots__verdict {
  font-family: "Proxima Nova Cond", arial;
  font-size: 17px; }

.tip__info {
  margin: 12px; }

.tip__img {
  align-self: center;
  width: 60px;
  margin: -5px 10px 0 0;
  float: left;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.tip__horse-name {
  font-family: "Proxima Nova Cond", arial;
  font-size: 20px;
  font-weight: bold; }

.tip__verdict {
  margin-top: 5px;
  margin-left: 70px; }

.betting-forecast {
  padding: 13px;
  padding-top: 12px;
  margin-left: -7px;
  margin-right: -7px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: 0;
  margin-bottom: -1px; }

.race-card__footer {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  padding: 13px;
  display: flex;
  margin-left: -7px;
  margin-right: -7px; }

.race-card__footer--flex {
  display: flex; }

.no-runners--container {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  margin-left: -7px;
  margin-right: -7px;
  font-size: 20px;
  background-image: repeating-linear-gradient(135deg, lightgray, rgba(255, 255, 255, 0.3) 9px, rgba(255, 255, 255, 0.3) 10px), repeating-linear-gradient(45deg, lightgray, rgba(255, 255, 255, 0.3) 9px, rgba(255, 255, 255, 0.3) 10px); }

.elements__container {
  border-top: 1px solid #000;
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px; }

.elements-top-border-greyout {
  border-top: 1px solid darkgrey; }

.elements-top-border {
  border-top: 1px solid #000; }

.element {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px; }

.element-side-borders {
  border-left: 1px solid #000;
  border-right: 1px solid #000; }

.element-side-borders-greyout {
  border-left: 1px solid darkgray;
  border-right: 1px solid darkgray; }

.element-race-separator {
  margin-top: 13px; }

.element-bottom-border {
  border-bottom: 1px solid #000; }

.element-bottom-border-greyout {
  border-bottom: 1px solid darkgray; }

.column__container {
  height: calc(100vh - 215px);
  flex-direction: column;
  top: 115px;
  position: relative;
  padding: 0 13px 13px 13px;
  display: flex; }
  .column__container .element:first-of-type
> .spotlight_container
> div
> div:first-of-type
> .spotlight__indent-container:first-of-type {
    margin-top: 6px; }
  .column__container .SpotlightGroupContainer--last > div > div:nth-of-type(2) {
    margin-bottom: 3px; }
  .column__container .SpotlightGroupContainer--last {
    padding-bottom: 3px; }
  .column__container .SpotlightGroup:last-child {
    padding-bottom: 6px; }
  .column__container .SpotlightGroup--last {
    margin-bottom: 3px; }

.column-elements__container {
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px; }
  .column-elements__container:after {
    content: '';
    height: 1px;
    background: black;
    z-index: 100;
    position: absolute;
    left: 6px;
    right: 6px; }

.column-elements-greyout__container:after {
  background: silver; }

.column-ads__container {
  justify-content: flex-end;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: overlay; }

.column-tipster__container {
  height: 100%; }

.column-how-to-guide__container {
  margin-top: 13px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  margin-left: -7px;
  margin-right: -7px; }

.header__container {
  display: flex;
  position: absolute;
  height: 100px;
  color: #fff;
  align-items: center;
  font-weight: bolder;
  font-size: 60px;
  padding: 10px;
  justify-content: space-between;
  font-family: "Proxima Nova Bold"; }

.header__meeting {
  display: flex;
  width: 100%;
  align-items: center; }

.header-name--continued {
  font-size: 2rem;
  width: 75%; }

.header__meeting-info {
  margin-top: 37px;
  margin-left: 10px; }

.header__race-info {
  display: flex;
  margin-top: -8px; }

.header__weather {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  text-transform: none; }

.header__going {
  margin-top: 20px;
  margin-right: 20px; }

.header__temperature {
  margin-left: 10px;
  font-family: "Proxima Nova", Georgia, "Times New Roman", Times, serif;
  padding-top: 5px; }

.header__weather--img {
  width: 90px;
  height: 90px; }

.header__logo {
  height: 35px; }

.header--border {
  margin-left: 0; }

.header--continued {
  font-size: 20px;
  width: 25%;
  display: flex;
  justify-content: flex-end; }

.header--small {
  width: 80%; }

.header--continued {
  border-top-left-radius: 0px; }

.header-continuing-next-screen {
  border-top-right-radius: 0px; }

.header-continued-prev-screen {
  border-top-left-radius: 0px; }

.socket-connection {
  position: absolute;
  top: 0;
  left: 37%;
  background-color: yellow;
  padding: 20px;
  font-size: 25px;
  color: black;
  font-weight: bolder;
  z-index: 2; }

.network-connection {
  position: absolute;
  top: 0;
  left: 34%;
  background-color: red;
  padding: 20px;
  font-size: 25px;
  color: white;
  z-index: 3;
  font-weight: bolder; }

.render-screen__container {
  display: flex;
  position: absolute;
  top: 100px; }

@-webkit-keyframes Gallop {
  from {
    font-variation-settings: 'TIME' 0; }
  to {
    font-variation-settings: 'TIME' 1000; } }

@keyframes Gallop {
  from {
    font-variation-settings: 'TIME' 0; }
  to {
    font-variation-settings: 'TIME' 1000; } }

.horse__container {
  font: 140px Muybridge;
  -webkit-animation: 0.6s linear infinite Gallop;
          animation: 0.6s linear infinite Gallop; }

.content__progress {
  width: 400px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  height: 2px; }
  .content__progress span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: red;
    -webkit-animation: loader 2.2s cubic-bezier(0.8, 0, 0.2, 1);
            animation: loader 2.2s cubic-bezier(0.8, 0, 0.2, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

@-webkit-keyframes loader {
  0% {
    width: 0%;
    left: 0; }
  49% {
    width: 100%;
    left: 0; }
  50% {
    width: 100%;
    right: 0;
    left: auto; }
  100% {
    width: 0%;
    right: 0;
    left: auto; } }

@keyframes loader {
  0% {
    width: 0%;
    left: 0; }
  49% {
    width: 100%;
    left: 0; }
  50% {
    width: 100%;
    right: 0;
    left: auto; }
  100% {
    width: 0%;
    right: 0;
    left: auto; } }

.loading__container {
  background-color: #000;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  font-size: 60px;
  color: #fff;
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000; }

.loading__logo {
  margin-bottom: 20px;
  height: 60px; }

.loading__text {
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex; }
  .loading__text p > {
    text-align: center; }

.dev-tools__container {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: black;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  color: white;
  padding: 1rem;
  display: flex;
  z-index: 11;
  top: -145px;
  left: 0; }
  .dev-tools__container:hover {
    top: 0; }

.dev-tools__title {
  margin: 0.25rem 0;
  font-size: 24px; }

.dev-tools__button {
  background-color: transparent;
  font-size: 24px;
  color: white;
  border: none;
  cursor: pointer; }

.console-debug {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  width: 600px;
  overflow: scroll;
  background-color: black;
  z-index: 10; }

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../../static/media/Proxima-Nova-Reg.f44790079cc3eed0ffcf7ca4eca19267.otf);
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url(../../static/media/Proxima-Nova-Bold.187608336918c9f3d2d2fd47c86ea4d7.otf);
}

@font-face {
  font-family: 'Proxima Nova Bold Italic';
  src: url(../../static/media/Proxima-Nova-Bold-It.184a75e6fa632878bf3f69f6e5feb12b.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond';
  src: url(../../static/media/Proxima-Nova-Cond-Reg.f93e064fca71022b8891704b52ea3348.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Black';
  src: url(../../static/media/Proxima-Nova-Cond-Black.71af6ceed89f83defb122f44ef828f66.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Extra Bold';
  src: url(../../static/media/Proxima-Nova-Cond-ExtraBold.75e7dfb08b86403c8f83be4630cf63b4.otf);
}

@font-face {
  font-family: 'Swis721BT';
  src: url(../../static/media/Swis721BT-Regular2.11d7a8e70986ed8eca569c5876531d94.otf);
}

@font-face {
  font-family: 'Swis721BT Black';
  src: url(../../static/media/Swiss721BT-Black.19cc39a9b68efaeb4f12ae23dfbdf82b.otf);
}

@font-face {
  font-family: 'Muybridge';
  src: url(../../static/media/MuybridgeGX.5e7f9c498d58ee7d83b6102a461a69e8.woff2);
}

@font-face {
  font-family: 'Proxima Nova Cond Medium';
  src: url(../../static/media/Proxima-Nova-Cond-Medium.19df696b5532316dde1521e7ee213118.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Semi Bold';
  src: url(../../static/media/Proxima-Nova-Cond-Semibold.03607c388cc2942dbdf4129e0c9cabba.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Semi Bold Italic';
  src: url(../../static/media/Proxima-Nova-Cond-Semibold-It.5a676922887eb2d9cd5171f21fe90f07.otf);
}
@font-face {
  font-family: 'Proxima Nova Extra Bold';
  src: url(../../static/media/Proxima-Nova-Extrabold.6ed5630622a22971177aa7cd608d073b.otf);
}

@font-face {
  font-family: 'Proxima Nova Medium';
  src: url(../../static/media/Proxima-Nova-Medium.eda30cbff3613beec9c6dc8ba08ec458.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Bold';
  src: url(../../static/media/Proxima-Nova-Cond-Bold.e134671469dee52920d4e9972d3a7dce.otf);
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url(../../static/media/Proxima-Nova-Reg.f44790079cc3eed0ffcf7ca4eca19267.otf);
}

@font-face {
  font-family: 'Proxima Nova Cond Regular';
  src: url(../../static/media/Proxima-Nova-Cond-Reg.f93e064fca71022b8891704b52ea3348.otf);
}

@font-face {
  font-family: 'Proxima Nova Semi Bold';
  src: url(../../static/media/Proxima-Nova-Semibold.b9dcb62eccce0b9768fc44d1432da0f8.otf);
}

body {
  background-color: #fff;
  line-height: inherit; }

hr {
  width: 100%; }

