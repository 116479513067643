@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.spotlight__title-container {
  align-items: center;
  padding-bottom: 10px;
  padding-top: 15px;
  display: flex;
}

.spotlight__logo {
  width: 250px;
  margin-right: 8px;
  height: 15px;
}

.spotlight__horse-name {
  margin-right: 10px;
  font-weight: bold;
}

.spotlight__indent-container {
  padding-left: 22px;
  text-indent: -22px;
  line-height: 17px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.spotlight_container {
  overflow: hidden;
  padding-left: 7px;
  padding-right: 7px;
}

.spotlight__inline-spotlight {
  line-height: 1;
  padding-top: 2px;
}

.spotlight_container-no-rpr {
  margin-bottom: 15px !important ;
}

.spotlight__non-runner {
  p {
    opacity: 0.5;
  }
}
