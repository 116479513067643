@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.loading__container {
  background-color: $black;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  font-size: 60px;
  color: $white;
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
}

.loading__logo {
  margin-bottom: 20px;
  height: 60px;
}

.loading__text {
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

  p > {
    text-align: center;
  }
}
