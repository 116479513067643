@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima-Nova-Reg.otf');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('../assets/fonts/Proxima-Nova-Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Bold Italic';
  src: url('../assets/fonts/Proxima-Nova-Bold-It.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond';
  src: url('../assets/fonts/Proxima-Nova-Cond-Reg.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Black';
  src: url('../assets/fonts/Proxima-Nova-Cond-Black.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Extra Bold';
  src: url('../assets/fonts/Proxima-Nova-Cond-ExtraBold.otf');
}

@font-face {
  font-family: 'Swis721BT';
  src: url('../assets/fonts/Swis721BT-Regular2.otf');
}

@font-face {
  font-family: 'Swis721BT Black';
  src: url('../assets/fonts/Swiss721BT-Black.otf');
}

@font-face {
  font-family: 'Muybridge';
  src: url('../assets/fonts/MuybridgeGX.woff2');
}

@font-face {
  font-family: 'Proxima Nova Cond Medium';
  src: url('../assets/fonts/Proxima-Nova-Cond-Medium.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Semi Bold';
  src: url('../assets/fonts/Proxima-Nova-Cond-Semibold.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Semi Bold Italic';
  src: url('../assets/fonts/Proxima-Nova-Cond-Semibold-It.otf');
}
@font-face {
  font-family: 'Proxima Nova Extra Bold';
  src: url('../assets/fonts/Proxima-Nova-Extrabold.otf');
}

@font-face {
  font-family: 'Proxima Nova Medium';
  src: url('../assets/fonts/Proxima-Nova-Medium.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Bold';
  src: url('../assets/fonts/Proxima-Nova-Cond-Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../assets/fonts/Proxima-Nova-Reg.otf');
}

@font-face {
  font-family: 'Proxima Nova Cond Regular';
  src: url('../assets/fonts/Proxima-Nova-Cond-Reg.otf');
}

@font-face {
  font-family: 'Proxima Nova Semi Bold';
  src: url('../assets/fonts/Proxima-Nova-Semibold.otf');
}
