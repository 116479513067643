@import './src/styles/helpers.scss';

.favourites__year {
  min-width: 120px;
  vertical-align: top;
}

.favourites__wins-rides-percentage {
  min-width: 30px;
}

.favourites__stake {
  min-width: 45px;
}
