@import './src/styles/colors.scss';
@import './src/styles/tables.scss';

.top-draw__section {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: 0.25rem;
  width: 100%;
}

.top-draw__list {
  list-style-type: disc;
  list-style-position: inside;
}
