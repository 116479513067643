@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';
@import './src/styles/tables.scss';

// Container
.top-jockey__container {
  font-size: 0.7rem;
  overflow: initial;
}

.top-jockeys--flex {
  display: flex;
}

.top-jockeys--flex-one {
  flex: 1;
}

.top-jockeys--flex-half {
  flex: 0.5;
}

.top-jockeys--flex-quarter {
  flex: 0.25;
}

.top-jockeys--align-end {
  align-items: flex-end;
}

.top-jockeys--flex-between {
  justify-content: space-between;
}

.top-jockeys--flex-col {
  flex-direction: column;
}

.top-jockeys__container {
  flex-direction: column;
  display: flex;
}

.top-jockeys--align-right {
  text-align: right;
}

.top-jockeys--align-left {
  text-align: left;
}

.top-jockeys__header {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.top-jockeys--border-left {
  border-left: 1px solid black;
}

.top-jockeys__row {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.top-jockeys__wins-rides-percent {
  min-width: 15%;
}

.top-jockeys__percent {
  text-align: right;
  min-width: 24px;
}

.top-jockeys__jockey-trainer-name {
  justify-content: space-between;
  width: 75%;
}

.top-jockeys__jockey-trainer-name-header {
  width: 75%;
}

.top-jockeys__stake {
  padding: 1px 5px 1px 5px;
  min-width: 9%;
}

.top-jockeys__rides-since {
  min-width: 5%;
}

.top-jockeys__wins {
  min-width: 20px;
}
