.dev-tools__container {
  transition: all 0.2s ease-in-out;
  background-color: black;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  color: white;
  padding: 1rem;
  display: flex;
  z-index: 11;
  top: -145px;
  left: 0;

  &:hover {
    top: 0;
  }
}

.dev-tools__title {
  margin: 0.25rem 0;
  font-size: 24px;
}

.dev-tools__button {
  background-color: transparent;
  font-size: 24px;
  color: white;
  border: none;
  cursor: pointer;
}
