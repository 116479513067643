@import './colors.scss';

body {
  background-color: $backgroundColor;
  line-height: inherit;
}

hr {
  width: 100%;
}
