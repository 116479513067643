// Fonts
$primaryFont: 'Proxima Nova', Georgia, 'Times New Roman', Times, serif;
$secondaryFont: 'Swis721BT', arial;
$tertiaryFont: 'Proxima Nova Cond', arial;

// Alternatives
$proximaBold: 'Proxima Nova Bold';
$proximaBoldItalic: 'Proxima Nova Bold Italic';
$proximaCondBlack: 'Proxima Nova Cond Black';
$proximaCondExtraBold: 'Proxima Nova Cond Extra Bold';
$swisBlack: 'Swis721BT Black';
$timesRoman: 'Times New Roman';
$proximaCondSemiBold: 'Proxima Nova Cond Semi Bold';
$proximaCondSemiBoldIt: 'Proxima Nova Cond Semi Bold Italic';
$proximaCondMedium: 'Proxima Nova Cond Medium';
$proximaMedium: 'Proxima Nova Medium';
$proximaCondBold: 'Proxima Nova Cond Bold';
$proximaExtraBold: 'Proxima Nova Extra Bold';
$proximaRegular: 'Proxima Nova Regular';
$proximaCondRegular: 'Proxima Nova Cond Regular';
$proximaSemiBold: 'Proxima Nova Semi Bold';
