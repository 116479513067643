@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

.raceboard__container {
  border-top: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-bottom: 2px solid $borderColor;
  margin-left: -7px;
  margin-right: -7px;
}

.raceboard__time-info {
  display: flex;
  flex: 1;
  align-items: center;
}

.raceboard__inner-container {
  justify-content: space-between;
  display: flex;
  padding-bottom: 0;
  flex-wrap: wrap;
  padding: 3px 13px 5px 13px;
  height: 65px;
}

.raceboard__race-num {
  font-weight: bolder;
  font-family: $proximaExtraBold;
  text-transform: uppercase;
}

.raceboard__tv-container {
  justify-content: flex-end;
  display: flex;
  margin: 6px;
  margin-top: 8px;
  margin-right: 0;
}

.raceboard__tv-winner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.raceboard__tv {
  display: flex;
  background-color: $grey;
  color: $white;
  align-items: center;
  height: 20px;
  padding: 0px 10px;
  border-radius: 10px;
}

.raceboard__tv-logo-container {
  height: 42px;
}

.raceboard__distance {
  display: flex;
  justify-content: flex-end;
}

.raceboard__info {
  flex: 1;
  font-family: $proximaCondMedium;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
  padding-left: 5px;
  padding-bottom: 2px;
}

.raceboard__class-winner {
  display: flex;
}

.raceboard__race-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $black;
  color: $white;
  width: 100%;
  padding: 3px 13px 1px 13px;
  font-size: 17px;
  font-family: $proximaExtraBold;
}
