@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';

$tablePadding: 4px;

.ten-year__table {
  font-family: $tertiaryFont;
  columns: 2;
  td:not(:first-child) {
    text-align: right;
  }
}

.ten-year__table-item {
  border-bottom: 5px solid black;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.ten-year__table--border {
  border-bottom: 1px solid black;
  border-top: 1px solid black;

  td {
    padding-top: $tablePadding;
    padding-bottom: $tablePadding;
  }
}

.ten-year__table--border-bottom {
  border-bottom: 1px solid black;
}

.ten-year__table--border-top {
  border-top: 1px solid black;
}

.ten-year__table--main-title {
  font-weight: bold;

  td:not(:first-child) {
    font-style: italic;
  }
}

.ten-year__table--sub-title {
  font-style: italic;
  td {
    padding-top: $tablePadding;
  }
}

.ten-year__table--bottom-pad {
  td {
    padding-bottom: $tablePadding;
  }
}
