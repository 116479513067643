$headerBaseHeight: 100;
$infoBarBaseHeight: 100;
$marginColumnTop: 15;
$headerHeight: $headerBaseHeight + unquote('px');
$infoBarHeight: $infoBarBaseHeight + unquote('px');
$infoAndHeader: $headerBaseHeight + $infoBarBaseHeight + $marginColumnTop +
  unquote('px');

$jsHeaderAndInfoBar: $headerBaseHeight + $infoBarBaseHeight + $marginColumnTop;

$spacingExtraSmall: 5px;
$spacingSmall: 10px;
$spacingMedium: 15px;
$spacing: 20px;
$spacingBig: 40px;

$ghAnimationBase: 500;
$ghAnimationSizeBase: 650;

$ghAnimationTime: $ghAnimationBase + unquote('ms');
$ghAnimationWidth: $ghAnimationSizeBase + unquote('px');

:export {
  infoandheaderheight: $jsHeaderAndInfoBar;
  ghanimationtime: $ghAnimationBase;
  ghanimationsize: $ghAnimationSizeBase;
}
