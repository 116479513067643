@import './src/styles/colors.scss';
@import './src/styles/fonts.scss';
@import './src/styles/vars.scss';

.column__container {
  height: calc(100vh - #{$infoAndHeader});
  flex-direction: column;
  top: $headerBaseHeight + $marginColumnTop + unquote('px');
  position: relative;
  padding: 0 13px 13px 13px;
  display: flex;

  // Top and bottom spacings for Spotlights
  .element:first-of-type
    > .spotlight_container
    > div
    > div:first-of-type
    > .spotlight__indent-container:first-of-type {
    margin-top: 6px;
  }

  .SpotlightGroupContainer--last > div > div:nth-of-type(2) {
    margin-bottom: 3px;
  }

  .SpotlightGroupContainer--last {
    padding-bottom: 3px;
  }

  .SpotlightGroup:last-child {
    padding-bottom: 6px;
  }

  .SpotlightGroup--last {
    margin-bottom: 3px;
  }
}

.column-elements__container {
  margin-left: -7px;
  margin-right: -7px;
  padding-left: 7px;
  padding-right: 7px;

  // Faux-bottom-border
  &:after {
    content: '';
    height: 1px;
    background: black;
    z-index: 100;
    position: absolute;
    left: 6px;
    right: 6px;
  }
}

.column-elements-greyout__container {
  // Faux-bottom-border
  &:after {
    background: silver;
  }
}

.column-ads__container {
  justify-content: flex-end;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: overlay;
}

.column-tipster__container {
  height: 100%;
}

.column-how-to-guide__container {
  margin-top: 13px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  margin-left: -7px;
  margin-right: -7px;
}
